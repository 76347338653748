import React, { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  Button, CircularProgress, Grid, Select, SelectChangeEvent
} from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { CartItem, Modifier, Product } from '@pizza-hut-us-development/client-core';
import useAddToCart from '@/clientCore/menu/hooks/useAddToCart';
import { closeModal, openModal } from '@/localization/actions';
import { glutenFreeDetails } from '@/builders/pizza/PizzaBuilder/GlutenFreeDetails/GlutenFreeDetails';
import { useStyles } from './AddToCart.styles';
import { useShowCartToast } from '@/clientCore/menu/hooks/useShowCartToast';
import { OneClickPizzaOOSBody } from '@/builders/pizza/hooks/useOutOfStockModal/constants';

export const ADD_TO_CART_BUTTON_TESTID = 'add-to-cart-button';
export const QUANTITY_SELECTOR_TESTID = 'quantity-selector';

const BASE_QUANTITY = 1;
const MAX_QUANTITY = 10;

const ArrowDown = forwardRef<SVGSVGElement, React.ComponentProps<typeof KeyboardArrowDownOutlinedIcon>>((props, ref) => (
  <KeyboardArrowDownOutlinedIcon {...props} ref={ref} style={{ cursor: "pointer" }} />
));

const getGlutenFreeModalContent = (callback: () => void): ModalContent => {
  const glutenModalContent = glutenFreeDetails();
  return {
    ...glutenModalContent,
    hideCloseIcon: true,
    cta: {
      ...glutenModalContent.cta,
      callback: () => {
        if (glutenModalContent.cta.callback) glutenModalContent.cta.callback();
        callback();
      }
    }
  };
};

export interface AddToCartProps {
  product: Product;
  disabled?: boolean;
  buttonText?: string;
  showQuantity?: boolean;
  handleAnalyticsAddToCartClick?(quantity: number): void;
  handleAnalyticsAddToCartSuccess?(quantity: number): void;
}

const quantitySelectOptions = Array.from(Array(MAX_QUANTITY).keys())
  .map((num) => {
    const quantity = num + 1;
    return <option key={quantity} value={quantity}>{quantity}</option>;
  });

export function AddToCart(props: AddToCartProps) {
  const {
    product,
    disabled,
    buttonText = 'ADD',
    showQuantity = true,
    handleAnalyticsAddToCartClick,
    handleAnalyticsAddToCartSuccess
  } = props;
  const dispatch = useDispatch();
  const { addToCart, isLoading } = useAddToCart();
  const { showCartToast } = useShowCartToast();
  const [quantity, setQuantity] = useState<number>(BASE_QUANTITY);
  const classes = useStyles();

  const onAddToCartSuccess = (item: CartItem) => {
    const onSuccess = () => {
      handleAnalyticsAddToCartSuccess?.(quantity);
      setQuantity(BASE_QUANTITY);
      showCartToast(item);
    };

    if (product.glutenFree) {
      dispatch(openModal(getGlutenFreeModalContent(onSuccess)));
    } else {
      onSuccess();
    }
  };
  const checkOOSForSelectedOptions = (selectedProduct: Product) => selectedProduct?.selectedOptions?.filter((option) => option.outOfStock);

  const getOOSModalDetails = (oosSelectedOptions:Modifier[], selectedProduct: Product): ModalContent => ({
    title: 'Topping so good, they\'re gone',
    body: OneClickPizzaOOSBody.replaceAll('[ingredients]', oosSelectedOptions.map((option) => option.name).join(', ')),
    altCta: {
      text: 'Cancel',
      reverseButtonsOrder: true,
      callback: () => dispatch(closeModal())
    },
    cta: {
      text: 'Add to cart',
      reverseButtonsOrder: true,
      callback: () => {
        addToCart({
          ...selectedProduct,
          selectedOptions: selectedProduct.selectedOptions.filter((option) => !option.outOfStock)
        }, quantity, onAddToCartSuccess);
        dispatch(closeModal());
      }
    },
    hideCloseIcon: false
  });

  const handleAddToCart = () => {
    if (isLoading) return;
    handleAnalyticsAddToCartClick?.(quantity);
    const oosSelectedOptions = checkOOSForSelectedOptions(product);
    if (oosSelectedOptions?.length > 0) {
      const modalDetails = getOOSModalDetails(oosSelectedOptions, product);
      dispatch(openModal(modalDetails));
      return;
    }
    addToCart(product, quantity, onAddToCartSuccess);
  };

  const handleQuantityChange = (event: SelectChangeEvent<number>) => {
    let newQuantity = parseInt(event.target.value as string ?? '', 10);
    if (Number.isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = BASE_QUANTITY;
    } else if (newQuantity > MAX_QUANTITY) {
      newQuantity = MAX_QUANTITY;
    }
    setQuantity(newQuantity);
  };

  return (
    <Grid item container wrap="nowrap" justifyContent="flex-end">
      {showQuantity && (
        <Select
          native
          className={classes.quantityBtn}
          onChange={handleQuantityChange}
          disabled={disabled || isLoading}
          IconComponent={ArrowDown}
          value={quantity}
          inputProps={{
            name: 'quantity',
            id: 'pizza-quantity-select',
            className: classes.quantitySelect,
            tabIndex: 0,
            'data-testid': QUANTITY_SELECTOR_TESTID
          }}
        >
          {quantitySelectOptions}
        </Select>
      )}
      <Button
        data-testid={ADD_TO_CART_BUTTON_TESTID}
        className={clsx(classes.addBtn, !showQuantity && classes.hasNoQuantity)}
        onClick={handleAddToCart}
        variant="contained"
        color="primary"
        disabled={disabled}
        aria-label={buttonText}
      >
        {isLoading ? <CircularProgress size={24} /> : buttonText}
      </Button>
    </Grid>
  );
}
