import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import fontStyles from '@/common/fontStyles';

export const useMenuTileStyles = makeStyles<Theme>(() => ({
  title: {
    ...fontStyles.productTitle,
    fontSize: '14px'
  }
}));
