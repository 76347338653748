import makeStyles from '@mui/styles/makeStyles';
import fontStyles from '@/common/fontStyles';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import colors from '@/common/colors';

export const useStyles = makeStyles((theme) => ({
  addBtn: {
    ...fontStyles.primaryCtaLabel,
    flex: 2,
    'text-transform': 'uppercase',
    height: '32px',
    padding: '6px 0 7px 0',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    border: `solid 1px ${colors.red}`
  },
  hasNoQuantity: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    width: '226px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      width: '100%'
    },
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      width: '226px'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  quantitySelect: {
    borderRadius: '8px 0 0 8px',
    paddingLeft: '11px',
    height: '17px',
    lineHeight: '17px',
    borderRight: '0',
    paddingRight: '0 !important',
    '&:focus': {
      borderRadius: '8px 0 0 8px'
    }
  },
  quantityBtn: {
    height: '32px',
    width: '58px',
    borderRadius: '8px 0 0 8px'
  },
  screenReadersOnly: {
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap', /* added line */
    border: '0'
  }
}));
